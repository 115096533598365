/* You can add global styles to this file, and also import other style files */

/* Note with INFO Icon */

.cs-note {
  color: var(--text-faded);
  font-size: 0.6875rem;
}

.cs-note::before {
  content: 'i';
  display: inline-block;
  height: 1rem;
  width: 1rem;
  border-radius: 1.875rem;
  background-color: #ffc107;
  margin: 0 0.4rem 0 0;
  color: var(--bg-card);
  text-align: center;
}

/* File Upload */
.file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.file-upload .file-select {
  display: block;
  /* border: 2px solid #dce4ec; */
  border: 1px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: auto;
  /* line-height: 30px; */
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
}

.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0.5rem 0.625rem;
  display: inline-block;
  height: auto;
  /* line-height: 30px; */
  position: relative;
  /* top: -2px; */
}

.file-upload .file-select .file-select-name {
  /* height: 30px; */
  /* line-height: 30px; */
  position: relative;
  /* top: -2px; */
  display: inline-block;
  padding: 0 10px;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type='file'] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 70%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.btn-upload {
  position: absolute;
  height: auto;
  top: 50%;
  right: 0;
  background-color: #d2e4ff;
  color: var(--theme-color);
  padding: 5px 16px;
  border-radius: 0.25rem;
  /* font-size: 10px; */
  margin: 0px 4px;
  transform: translate(0, -50%);
}

.cs-btn-upload-close {
  position: absolute;
  top: 50%;
  right: 4rem;
  padding: 0.13rem 1rem;
  font-weight: bold;
  transform: translate(0, -50%);
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-light);
}

.btn-primary {
  background: var(--theme-color);
}

.form-section-header {
  border-radius: 0.125rem;
  padding: 0.5rem 0;
  font-weight: bold;
}

.form-section-header .title {
  font-size: 0.625rem;
  line-height: 1.5;
}

.help-btn {
  border: 2px solid var(--border-main);
  border-radius: 100%;
  height: 0.9375rem;
  width: 0.9375rem;
  font-size: 0.5rem;
}

span.help-btn {
  display: inline-block;
  border: 2px solid var(--border-main);
  border-radius: 100%;
  height: 0.9375rem;
  width: 0.9375rem;
  font-size: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.cs-form-card {
  border: 1px solid var(--border-main);
  padding: 0.5rem 0.625rem;
  border-radius: 0.25rem;
  background-color: #fff;
}
.cs-form-card .title {
  font-size: 0.875rem;
  line-height: 1.5;
}
.cs-form-card .description {
  font-size: 0.6rem;
  line-height: 1.5;
  color: var(--border-primary);
}

/* Action Buttons */
.action-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.action-btn.center {
  justify-content: center;
  align-items: center;
}

.action-btn.right {
  justify-content: flex-end;
  align-items: center;
}

/* Modal Common */
.modal-fullpage .modal-content {
  background-color: var(--border-light);
}

/* OR Section */

.divider {
  display: flex;
}
.divider:before,
.divider:after {
  content: '';
  flex: 1;
}

.line {
  align-items: center;
  margin: 1em -1em;
}
.line:before,
.line:after {
  height: 1px;
  margin: 0 1em;
}

.one-line:before,
.one-line:after {
  background: #0001;
}
